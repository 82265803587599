<template>
 <div>
   <b-row>
    <b-col md="1">
    </b-col>
    <b-col md="10">
      <b-card md="1" bg-variant="none"> {{description}}</b-card>
    </b-col>
    <b-col md="1">
    </b-col>
   </b-row>
   <br>
    <b-row>
      <b-col md="1">
      </b-col>
      <b-col md="10">
        <b-card bg-variant="light">
          <b-form-group 
            label-cols-lg="3"
            label="Product Listing"
            label-size="lg"
            label-class="font-weight-bold pt-0"
            class="mb-0"
          >
            <b-form-group
              label="Edition:"
              label-for="nested-edition"
              label-cols-sm="3"
              label-align-sm="right"
            >
              <b-form-input id="nested-edition" type="number"
                v-model="attr_copy.edition"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label="Price:"
              label-for="nested-price-cents"
              label-cols-sm="3"
              label-align-sm="right"
            >
              <b-input-group size="sm" prepend="$">
                <b-form-input-currency id="nested-price-cents"
                  v-model="attr_copy['price-cents']"
                ></b-form-input-currency>
              </b-input-group>
            </b-form-group>
            <b-form-group
              label="Description:"
              label-for="nested-description"
              label-cols-sm="3"
              label-align-sm="right"
            >
              <b-form-input id="nested-description"
                v-model="attr_copy.description"
              ></b-form-input>
            </b-form-group>
          </b-form-group>
        </b-card>
      </b-col>
      <b-col md="1">
        <data-operations
          :modified="modified"
          @update="doUpdate"
          @undo="doUndo"
          @delete="doDelete"
          @cancel="doCancel"
          @help="doHelp"
        ></data-operations>
      </b-col>
    </b-row>
 </div>
</template>

<script>
import {http_v1} from '@/axios'
import {amsMofifiedAttrs} from '@/utils'
import {deepEqual} from '@/utils'
import {belongsToDescription} from '@/utils'
import DataOperations from './DataOperations.vue'
import BFormInputCurrency from './BFormInputCurrency.vue'

export default {
  components: { 
    BFormInputCurrency,
    DataOperations
  },
  props: {
    id: {
      Type: String
    }
  },
  data () {
    return {
      ams_type: 'product-listings',
      ams_api_path: 'api/admin/v1/',
      modified: false,
      attr_copy: {},
      attr_orig: {},
      description: undefined,
      describe_belongs_to: [
        {
          prepend: 'Brand: ',
          type: 'brands',
          description_attr: 'name',
          append: ', '
        },
        {
          prepend: ' ',
          type: 'product-models',
          description_attr: 'name',
          append: ' '
        }
      ]
    }
  },
  methods: {
    doUpdate() {
      this.callApi()
    },
    doUndo() {
        this.attr_copy = Object.assign( {}, this.attr_orig)
    },
    doCancel() {
      this.$router.go(-1)
    },
    doDelete() {
      http_v1.delete (
        this.apiPath()
      ).then(response => {
        if( response.status == 204) {
          this.$router.go(-1)
        }
      }).catch(error => {
        if( error.response && error.response.data) {
          var message = error.response.data.message || JSON.stringify(error.response.data)
          if (error.response.status === 500 ) message = "500 Server Error"
          this.$bvToast.toast(message, {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      })
    },
    doHelp() {
    },
    apiPath() {
      return this.ams_api_path + this.ams_type.replace(/-/g, '_') + '/' + this.id
    },
    callApi () {
      let attr_params = amsMofifiedAttrs(this.attr_copy, this.attr_orig)
      http_v1.patch (
         this.apiPath(), {
          ...attr_params
        }
      ).then(() => {
        this.$router.go(-1)
      }).catch(error => {
        window.console.log(error)
        if( error.response && error.response.data) {
          var message = error.response.data.message || JSON.stringify(error.response.data)
          if (error.response.status === 500 ) message = "500 Server Error"
          this.$bvToast.toast(message, {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      })
    },
  },
  watch: {
     attr_copy : {
        handler() {
          if(deepEqual(this.attr_copy,this.attr_orig)) {
            this.modified = false
          }
          else {
            this.modified = true
          }
        },
        deep: true
     }
  },
  mounted () {
    http_v1.get(this.apiPath(), {
    }).then(response => {
      if( response.data) {
        this.attr_copy = Object.assign( {}, response.data.data.attributes)
        this.attr_orig = Object.assign( {}, response.data.data.attributes)
        this.description = belongsToDescription(
          response.data.data, response.data.included, this.describe_belongs_to
        )
        this.$emit('gotAttrs', this.attr_copy, this.description)
      }
    }).catch(error => {
      if( error.response && error.response.data) {
          var message = error.response.data.message || JSON.stringify(error.response.data)
          if (error.response.status === 500 ) message = "500 Server Error"
          this.$bvToast.toast(message, {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      })
  }
}
</script>