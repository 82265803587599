<template>
  <div>
    <vuex-breadcrumb></vuex-breadcrumb>
    <product-listing-update 
      :id="id"
      @gotAttrs="assignAttrs"
    ></product-listing-update>
    <product-item-index
      :filtering="filtering"
      :ams_template="ams_template"
    ></product-item-index>
  </div>
</template>

<script>
import VuexBreadcrumb from './VuexBreadcrumb.vue'
import ProductListingUpdate from './ProductListingUpdate.vue'
import ProductItemIndex from './ProductItemIndex.vue'

export default {
  components: {
    VuexBreadcrumb,
    ProductListingUpdate,
    ProductItemIndex
  },
  props: {
    id: String
  },
  data () {
    return {
      filtering: {
        product_listing_ids: this.id
      },
      /*
      ProductItem(id: uuid, collection_id: uuid, product_listing_id: uuid, 
      description: text, serial_number: integer, 
      purchased_price_cents: integer, 
      estimated_min_price_cents: integer, estimated_max_price_cents: integer, 
      authenticated_min_price_cents: integer, 
      authenticated_max_price_cents: integer, 
      asking_price_cents: integer, 
      sold_price_cents: integer, 
      estimated_quality: integer, 
      authenticated_quality: integer, 
      at_status: integer, 
      collected_at: datetime, prepared_at: datetime, delivered_at: datetime, received_at: datetime, 
      created_at: datetime, updated_at: datetime)
      */
      ams_template: {
        type: 'product-items',
        description: undefined,
        attributes: {
          'product-listing-id': this.id,
          'collection-id': undefined,
          'purchased-price-cents': 0,
          'estimated-min-price-cents': 0,
          'estimated-max-price-cents': 0,
          'authenticated-min-price-cents': 0,
          'authenticated-max-price-cents': 0,
          'asking-price-cents': 0,
          'sold-price-cents': 0,
          'estimated-quality': 9000,
          'authenticated-quality': 0
        }
      },
      attrs: {},
    }
  },
  methods: {
    assignAttrs(attrs, description) {
      this.attrs = Object.assign( {}, attrs)
      this.ams_template.description = description + '# ' + attrs['edition']
    }
  }
}
</script>